import React, { Suspense, useEffect } from "react";
import "./App.scss";
import Footer from "./containers/Footer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import PublicSportsBook from "./views/pages/privatePages/sportsBook";
import BeforeLoginFooter from "./containers/BeforeLoginFooter";
import BeforeContact from "./views/pages/publicPages/FooterPages/BeforeContact";
import BeforePolicy from "./views/pages/publicPages/FooterPages/BeforePolicy";
import BeforeResponsible from "./views/pages/publicPages/FooterPages/BeforeResponsible";
import BeforeFairPlay from "./views/pages/publicPages/FooterPages/BeforeFairPlay";
import BeforeGameRule from "./views/pages/publicPages/FooterPages/BeforeGameRule";
import BeforeTermsCondition from "./views/pages/publicPages/FooterPages/BeforeTermsCondition";
import Contact from "./views/pages/privatePages/FooterPages/Contact";
import FairPlay from "./views/pages/privatePages/FooterPages/FairPlay";
import GameRule from "./views/pages/privatePages/FooterPages/GameRule";
import Policy from "./views/pages/privatePages/FooterPages/Policy";
import Responsible from "./views/pages/privatePages/FooterPages/Responsible";
import TermsCondition from "./views/pages/privatePages/FooterPages/TermsCondition";
import ExchangeFund9Wicket from "./views/components/ExchangeFund9Wicket";
import LoaderImg from "./assets/images/loading-icon1.gif";
import SportsBook from "./views/pages/privatePages/sportsBook";
import DepositGateway from "./views/pages/privatePages/deposit/depositGateway";
import GatewaysList from "./views/pages/privatePages/deposit/gatewaysList";
import Referral from "./views/pages/privatePages/Referral";
import BonusCard from "./views/pages/privatePages/bonus/bonusCard";
import Bonus from "./views/pages/privatePages/bonus";

const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("./views/pages/privatePages/games/DynamicCasinoPage")
);
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const LandingPage = React.lazy(() =>
  import("./views/pages/publicPages/landingPage")
);
const Deposit = React.lazy(() => import("./views/pages/privatePages/deposit"));
const Withdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdraw")
);
const WithdrawList = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/WithdrawList")
);
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));
const Sports = React.lazy(() => import("./views/pages/privatePages/sports"));
const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const MatchDetailPage = React.lazy(() =>
  import("./views/pages/privatePages/sports/matchDetailPage")
);
const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));

const Betby = React.lazy(() => import("./views/pages/privatePages/betby"));

const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppDetails());
  }, []);
  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
  }, [appDetails]);
  return (
    <Router>
      <Suspense
        fallback={
          <div className="loader-img">
            <img src={LoaderImg} alt="loader" />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoutes isAuth={isAuth}>
                <LandingPage />
                <BeforeLoginFooter />
              </PublicRoutes>
            }
          />

          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
                {/*<ExchangeFund9Wicket />*/}
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit/:gateway"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateway />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gatewaylist"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GatewaysList />
                <Footer />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Deposit />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bonus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Bonus />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bonus/:id"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BonusCard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/referral"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Referral />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/sportsbook"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PublicSportsBook />
                {/* <Sports isAuth={isAuth} /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bet-by"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <SportsBook />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdrawList"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
                <Footer />
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          />
          <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route path="test" element={<Sports />} />
          <Route
            // path="/sports/exchange"
            path="/sports/:gameId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/betby"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Betby />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/detail-page/:slug/:matchId/:marketId/:sportId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MatchDetailPage />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/contact-us"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeContact />
              </PublicRoutes>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforePolicy />
              </PublicRoutes>
            }
          />
          <Route
            path="/responsible-game"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeResponsible />
              </PublicRoutes>
            }
          />
          <Route
            path="/fair-play"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeFairPlay />
              </PublicRoutes>
            }
          />
          <Route
            path="/game-rule"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeGameRule />
              </PublicRoutes>
            }
          />
          <Route
            path="/terms-conditions"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeTermsCondition />
              </PublicRoutes>
            }
          />
          <Route
            path="/contactus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Contact />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fairplay"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FairPlay />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gamesrule"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GameRule />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Policy />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/responsiblegame"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Responsible />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/termsconditions"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TermsCondition />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
