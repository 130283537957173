import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import logo from "../assets/svg/logo_new.png";
import { useSelector } from "react-redux";
const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <header className="header bfrlgn">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="logo">
                <a href="/">
                  {appDetails?.LOGO_URL && (
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  )}
                </a>
              </div>
              <div className="headerRight">
                {
                  <ul>
                    {/* <li>
                   <a
                className="panel"
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "#"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                      <TelegramIcon />
                    </a>
                  </li> */}
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <WhatsappIcon />
                      </a>
                    </li>
                  </ul>
                }
                <Button
                  variant=""
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                  className="btn-signin me-2"
                >
                  Sign In
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/mobile-number");
                  }}
                  className="btn-primary btn-register"
                >
                  Register
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
    </header>
  );
};

export default Header;
