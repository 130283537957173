import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

const BeforeGameRule = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div className="container">
        <div class="privacy_policy">
          <h3 class="mt-3">Game Rules</h3>
          <p>
            Are you tired of not understanding the rules of sports games? Do you
            want to place smart bets but are confused about the technicalities
            of the sport? Look no further than our guide to the rules of games!
          </p>
          <p>
            At our betting website, we believe that understanding the rules of a
            game is crucial to increasing your chances of winning. Whether it's
            football, basketball, or soccer, each sport has its own set of rules
            that determine gameplay and scoring.{" "}
          </p>
          <p>
            For example, in football, a touchdown is worth 6 points and can be
            scored by running the ball into the endzone or catching a pass in
            the endzone. And in basketball, each team has five players on the
            court at a time, with the objective being to shoot the ball through
            the opposing team's hoop to score points.
          </p>
          <p>
            Knowing these rules will help you better analyze the game and make
            informed betting decisions. If you're new to a sport, take the time
            to research the rules and understand how they work. Once you feel
            confident in your knowledge of the game, you can start placing smart
            bets and potentially increase your earnings.
          </p>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default BeforeGameRule;
