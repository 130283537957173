import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { socket } from "../../../../utils/socketUtil";
import { asyncSocketEmit } from "../../../../utils/socketUtil";
import { Toaster } from "../../../../utils/toaster";
import { APP_CONST } from "../../../../config/const";
import { useSelector } from "react-redux";

import { getBanners } from "../../../../redux/app/actions";
import { useDispatch } from "react-redux";

import Slider from "react-slick";
import MainSlider from "../../../../containers/MainSlider";

const SportsBook = () => {
  const { banners } = useSelector((state) => state.app);
  const [iframeUrl, setIframeUrl] = useState("");
  const isAuth = useSelector((state) => state.auth.isAuth);
  var width = window.screen.width;
  const isMobile = width < 800;
  document.body.style.setProperty("--header-background", `#050505`);

  useEffect(() => {
    if (isAuth) {
      getUrl();
    }
  }, [isAuth]);

  const getUrl = async () => {
    socket.emit(
      // "get:gamblebeast_lobby",
      // {
      //   mobile: isMobile,
      // },
      "get:spbalbert_lobby",
      {
        mobile: isMobile,
        lang: "en",
      },
      (res) => {
        console.log("sakshi", res);
        if (res?.status !== null) {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        }
        if (res?.data?.lobbyUrl) {
          setIframeUrl(res?.data?.lobbyUrl);
        }
      }
    );
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBanners());
  }, []);

  return (
    <>
      <HeaderAfterLogin />

      {/* <MainSlider banners={banners} className="" /> */}

      <div style={{ height: "100vh" }} className="test">
        <iframe
          src={iframeUrl}
          width="100%"
          style={{ height: "100%", border: "0" }}
        />
      </div>
    </>
  );
};
export default SportsBook;
