import React from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

const BeforeFairPlay = () => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="container">
        <div class="privacy_policy">
          <h3 class="mt-3">Fairplay</h3>
          <p>
            Betrollwin treats all Players equally and respectfully. Betrollwin
            Provide Fair Odds and Fair winnings. Fair competition, respect,
            friendship, team spirit, equality, sport without doping, respect for
            written and unwritten rules such as integrity, solidarity,
            tolerance, care, excellence and joy, are the building blocks of fair
            play that can be experienced and learnt both on and off the field.
          </p>
        </div>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default BeforeFairPlay;
