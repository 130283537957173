import { BALANCE_SUCCESS, LOGIN_SUCCESS, LOGOUT } from "./types";
import { axiosService, setUserToken } from "../../utils/axiosService";
import { API_URLS } from "../../config/apiUrls";
import { Toaster } from "../../utils/toaster";
import { APP_CONST } from "../../config/const";
import { socketFunc } from "../../utils/socketUtil";
// login user
export const loginUser = (payload) => (dispatch) => {
  try {
    const result = axiosService.post(API_URLS.AUTH.LOGIN_USER, payload);
    result
      .then((res) => {
        if (res.data.error === false) {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Login Success");
          socketFunc(res?.data?.data?.token).emit("get:balance", (data) => {
            if (data?.status === "success") {
              // console.log("sock func", data);
              dispatch(balanceUpdate(data));
            }
          });
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.data,
          });
          setUserToken(res.data.data?.token);
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, "Login Failed");
        }
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, "Login Failed");
      });
  } catch (err) {
    Toaster(APP_CONST.TOAST_TYPE.ERROR, "Login Failed");
    console.debug(err);
  }
};

//register User

export const registerUser = (paylaod, navigate) => (dispatch) => {
  try {
    const result = axiosService.post(
      API_URLS.AUTH.REGISTER_USER,
      JSON.stringify(paylaod),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    result
      .then((res) => {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Player added");
        localStorage.removeItem("mobile_details");
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res?.data?.data,
        });
        setUserToken(res.data.data?.token);
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  } catch (err) {
    console.debug(err);
  }
};

// send mobile otp
export const sendOtpToMobile = (paylaod) => (dispatch) => {
  try {
    const result = axiosService.post(
      API_URLS.AUTH.SEND_OTP_TO_MOBILE,
      JSON.stringify(paylaod)
    );
    return result;
  } catch (err) {
    console.debug(err);
  }
};

// verify otp
export const mobileVerification = (paylaod) => (dispatch) => {
  try {
    const result = axiosService.post(
      API_URLS.AUTH.MOBILE_VERIFICATION,
      JSON.stringify(paylaod)
    );
    return result;
  } catch (err) {
    console.debug(err);
  }
};

export const balanceUpdate = (data) => (dispatch) => {
  dispatch({
    type: BALANCE_SUCCESS,
    payload: data,
  });
};

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const updatePassword = (paylaod) => {
  try {
    return axiosService.post(API_URLS.AUTH.UPDATE_PASSWORD, paylaod);
  } catch (err) {
    console.debug(err);
  }
};
export const pageVisit = async (payload) => {
  try {
    const result = await axiosService.post(API_URLS.AUTH.PAGE_VISIT, payload);
    return result;
  } catch (err) {
    console.debug(err);
  }
};
