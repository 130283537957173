import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

const BeforePolicy = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div className="container">
        <div class="privacy_policy">
          <h3 class="mt-3">Privacy</h3>

          <h5>Dear valued users,</h5>
          <p>
            We are excited to announce that we have updated our privacy policy
            to better protect your personal information and ensure your
            continued trust in our platform.
          </p>
          <p>
            As an online betting site, we understand the importance of your
            privacy and are committed to ensuring that your personal information
            is always handled with the utmost care and in compliance with all
            applicable data protection regulations.
          </p>

          <p>
            Our updated privacy policy includes terms that provide greater
            transparency into our data collection and processing practices, the
            rights you have over your personal information, and the steps we
            take to keep your data safe.{" "}
          </p>
          <p>
            We encourage you to review our updated privacy policy in full,
            available on our site, to better understand how we collect and
            process your personal information.{" "}
          </p>
          <p>
            We want to thank you all for your continued support and trust in our
            platform. If you have any questions or concerns regarding our
            privacy policy, please do not hesitate to contact our customer
            support team.
          </p>

          <h5>Best Regards</h5>
          <p>Betfox</p>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default BeforePolicy;
