import React, { Component } from "react";
// import { Button } from "react-bootstrap";
import Slider from "react-slick";
// import MasterCard from "../assets/images/mastercard-pay.png";
// import VisaCard from "../assets/images/visacard-pay.png";
// import GPay from "../assets/images/GPay-pay.png";
import Banner1 from "../assets/images/banner/banner1.jpg";
import Banner2 from "../assets/images/banner/banner2.jpg";
import Banner3 from "../assets/images/banner/banner3.jpg";
import Banner4 from "../assets/images/banner/banner4.jpg";
import Banner5 from "../assets/images/banner/banner5.jpg";
import Banner6 from "../assets/images/banner/banner6.jpg";
import Banner7 from "../assets/images/banner/banner7.jpg";

const MainSlider = ({ banners }) => {
  const settings = {
    dots: true,
    // fade: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="mainBanner">
      <div className="home_banner">
        <Slider {...settings}>
          {banners &&
            banners.length > 0 &&
            banners.map((b, index) => {
              return (
                <div className="position-relative" key={index}>
                  <div className="content">
                    <div className="">
                      <div className="row">
                        <div className="col-12">
                          <img
                            src={b.link}
                            alt="Banner Image 1"
                            className="img-fluid"
                            style={{ aspectRatio: "16/9", objectFit: "fill" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div className="position-relative">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={Banner1}
                      alt="Banner Image 1"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={Banner2}
                      alt="Banner Image 2"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={Banner3}
                      alt="Banner Image 3"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={Banner4}
                      alt="Banner Image 4"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={Banner5}
                      alt="Banner Image 5"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={Banner6}
                      alt="Banner Image 6"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative">
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={Banner7}
                      alt="Banner Image 7"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </Slider>
      </div>

      {/* <Slider {...settings}>
        <div className="position-relative">
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-8">
                  <h2>
                    Play
                    <span>Online Casino</span>
                    &amp; Win Money Unlimited
                  </h2>
                  <p>THE UNLIMITED ONLINE CASINO PLATFORM.</p>
                  <Button variant="primary" className="button-primary">
                    PLAY NOW
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <img src="./images/banner-img-1.jpg" alt="First Slide" />
        </div>
        <div className="position-relative">
          <img src="./images/banner-img-2.jpg" alt="Second Slide" />
        </div>
        <div className="position-relative">
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-8">
                  <h2>
                    Play
                    <span>Online Casino</span>
                    &amp; Win Money Unlimited
                  </h2>
                  <p>THE UNLIMITED ONLINE CASINO PLATFORM.</p>
                  <Button variant="primary" className="button-primary">
                    PLAY NOW
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <img src="./images/banner-img-1.jpg" alt="Thired Slide" />
        </div>
      </Slider> */}

      {/* <Slider {...settings}>
        <div className="position-relative">
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="banner_info">
                    <h2>Casino made fast & easy</h2>
                    <ul className="payment">
                      <li>
                        <img src={MasterCard} alt="Master Card" />
                      </li>
                      <li>
                        <img src={VisaCard} alt="Visa Card" />
                      </li>
                      <li>
                        <img src={GPay} alt="GPay" />
                      </li>
                    </ul>
                    <Button variant="primary" className="button-primary">
                      PLAY NOW
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="position-relative">
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="banner_info">
                    <h2>Casino made fast & easy</h2>
                    <ul className="payment">
                      <li>
                        <img src={MasterCard} alt="Master Card" />
                      </li>
                      <li>
                        <img src={VisaCard} alt="Visa Card" />
                      </li>
                      <li>
                        <img src={GPay} alt="GPay" />
                      </li>
                    </ul>
                    <Button variant="primary" className="button-primary">
                      PLAY NOW
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider> */}
    </div>
  );
};

export default MainSlider;
