import React from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
// import Facebook from "../../../../assets/images/Facebook.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import { useSelector } from "react-redux";

const BeforeContact = () => {
  const { appDetails } = useSelector((state) => state.app);
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="container">
        <div className="contact-section">
          <h3>Contact Us</h3>
          <div className="contact-content">
            <a
              className="panel contact-one"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "#"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              <img src={Whatsapp} alt="Whatsapp Icon" />
              <p>Message Us Via WhatsApp</p>
            </a>
            <a
              className="panel  contact-one"
              href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
              target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
            >
              <img src={Telegram} alt="Telegram Icon" />
              <p>Chat With Us on Telegram</p>
            </a>
            <a
              className="panel contact-one"
              href={appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"}
              target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
            >
              <img src={Instagram} alt="Instagram Icon" />
              <p>Follow Us on Instagram</p>
            </a>
          </div>
        </div>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default BeforeContact;
