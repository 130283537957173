import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuIcon from "../assets/svg/MenuIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import RummyIcon from "../assets/images/Ezugi.png";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import GameShowIcon from "../assets/images/Casinoicon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import CashierIcon from "../assets/images/cashier.png";
import GamesIcon from "../assets/images/Evolution.png";
import HomeIcon from "../assets/images/Home.png";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
// import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/images/Sportsicon.png";
import numberWithCommas from "../utils/numbersWithComma";
import ProfileIconNew from "../assets/images/profile-icon.png";
import MyBetsIcon from "../assets/images/rupee.png";
import AccountStatement from "../assets/images/reporting.png";
import { APP_CONST } from "../config/const";
import { socket } from "../utils/socketUtil";
import { Toaster } from "../utils/toaster";
import bonus from "../assets/images/bonus.png";
import referral from "../assets/images/referral.png";

const HeaderAfterLogin = () => {
  const { user, wallet } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showToggle, setShowToggle] = useState(true);

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);

  useEffect(() => {
    setShowToggle(window.screen.width > 991.98);
    return () => {};
  }, []);
  const handleExchangeLaunch = () => {
    // console.log({ mobile: device?.device?.type === "smartphone" });
    socket.emit("get:spbwicket9_lobby", {}, (res) => {
      if (res.data?.error === true) {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        return false;
      }
      window.open(res?.data?.data?.lobbyUrl, "_blank");
    });

    return () => {
      socket.off("get:spbwicket9_lobby");
    };
  };
  return (
    <header className="header aftrlgn">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="logo">
                  <a href="/home">
                    {appDetails?.LOGO_URL && <img src={appDetails?.LOGO_URL} />}
                  </a>
                </div>
                <div className="header_mnu d-none d-md-block">
                  <Nav>
                    <Nav.Link href="/home">
                      <img src={HomeIcon} />
                      Home
                    </Nav.Link>
                    <Nav.Link href="/betby">
                      {/* <SportsIcon /> */}
                      <img src={SportsIcon} />
                      Sportsbook
                    </Nav.Link>
                    {/*<Nav.Link href="#" onClick={() => handleExchangeLaunch()}>
                      <SportsIcon /> Excahnge
                    </Nav.Link>*/}
                    <Nav.Link href="/casino/ezugi">
                      {/* <RummyIcon /> */}
                      <img src={RummyIcon} />
                      Ezugi
                    </Nav.Link>
                    <Nav.Link href="/casino/evolution">
                      {/* <GameShowIcon /> */}
                      <img src={GameShowIcon} />
                      Evolution
                    </Nav.Link>
                    <Nav.Link href="/casino/supernowa">
                      {/* <GamesIcon />  */}
                      <img src={GamesIcon} />
                      Supernowa
                    </Nav.Link>
                    <Nav.Link href="/cashier">
                      {/* <CashierIcon />  */}
                      <img src={CashierIcon} />
                      Cashier
                    </Nav.Link>
                  </Nav>
                </div>
                <Navbar expand="" className="d-none">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setShowToggle((s) => !s)}
                  />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                      <Nav.Link href="/home" className="logoImg">
                        {appDetails?.LOGO_URL && (
                          <img src={appDetails?.LOGO_URL} />
                        )}
                      </Nav.Link>
                      <Nav.Link href="/home">
                        {/* <HomeIcon /> */}
                        <img src={HomeIcon} />
                        Home
                      </Nav.Link>
                      <Nav.Link href="/sports/Cricket">
                        {/* <SportsIcon />  */}
                        <img src={SportsIcon} />
                        Sports
                      </Nav.Link>
                      <Nav.Link href="/casino/ezugi">
                        {/* <RummyIcon /> */}
                        <img src={RummyIcon} />
                        Ezugi
                      </Nav.Link>
                      <Nav.Link href="/casino/evolution">
                        {/* <GameShowIcon /> */}
                        <img src={GameShowIcon} />
                        Evolution
                      </Nav.Link>
                      <Nav.Link href="/casino/ezugi">
                        {/* <GamesIcon />  */}
                        <img src={GamesIcon} />
                        Casino
                      </Nav.Link>
                      <Nav.Link href="/cashier">
                        {/* <CashierIcon />  */}
                        <img src={CashierIcon} />
                        Cashier
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>

              <div className="headerRight">
                <div className="funds">
                  <ul>
                    {/* <li>
                      <a href="#">
                        Exch Bal:{" "}
                        <span>{numberWithCommas(wallet?.sports || 0)}</span>
                      </a>
                    </li> */}
                    <li>
                      <a href="#">
                        Balance:{" "}
                        <span>
                          INR {numberWithCommas(wallet?.balance || 0)}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <Button variant="primary menu-btn" onClick={handleShow}>
                  <MenuIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <div className="d-flex">
              <div className="profile-img">
                {/* <ProfileIcon /> */}
                <img src={ProfileIconNew} />
              </div>
              <div className="user-info">
                <span>{user?.mstruserid}</span>
                <p>{user?.mobileno}</p>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-6">
              <div className="balance mb-3">Main Balance</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-3">
                {numberWithCommas(wallet?.balance || 0)}
              </div>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/withdraw");
              }}
            >
              <Button variant="outline-secondary w-100">Withdraw</Button>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/gatewaylist");
              }}
            >
              <Button className="deposit-btn" variant="primary w-100">
                Deposit
              </Button>
            </div>
          </div>
          <ul className="menu-items">
            <li>
              <a href="/profile">
                {/* <ProfileIcon /> */}
                <img src={ProfileIconNew} />
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets">
                {/* <BetIcon /> */}
                <img src={MyBetsIcon} />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/bonus">
                {/* <StatementIcon /> */}
                <img src={bonus} />
                <span>Bonus</span>
              </a>
            </li>
            <li>
              <a href="/referral">
                {/* <StatementIcon /> */}
                <img src={referral} />
                <span>Referral</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                {/* <StatementIcon /> */}
                <img src={AccountStatement} />
                <span>Account Statement</span>
              </a>
            </li>
            {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
            {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
            <li onClick={() => dispatch(logoutUser())}>
              <a href="/">
                <LogoutIcon />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
          <ul className="social_links">
            <li>
              <a
                className="panel"
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <TelegramIcon />
              </a>
            </li>

            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <span className="notification-count">2</span>
                <WhatsappIcon />
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default HeaderAfterLogin;
