import React from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

const BeforeResponsible = () => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="container">
        <div class="privacy_policy">
          <h3 class="mt-3">Responsible Gaming</h3>
          <p>
            As a frequent user of my website, you already know that I always
            strive to provide you with the latest and greatest betting games.
            And boy, do I have some exciting news for you!
          </p>
          <p>
            Introducing the newest additions to my website's betting games:
            virtual sports betting and live casino games.
          </p>
          <p>
            Virtual sports betting is the perfect online betting solution for
            sports enthusiasts who want to get in on the action no matter the
            time of day. It's fast-paced, thrilling, and offers a wide range of
            betting options on a variety of virtual sports. From football to
            horseracing and greyhound racing, virtual sports betting has it all.
          </p>
          <p>
            If you're looking for a more immersive online betting experience,
            our live casino games will transport you straight to the casino
            floor. You can play classic casino games like roulette and
            blackjack, as well as newer variants like Dream Catcher and
            Lightning Roulette, all in real-time and with live dealers.
          </p>
          <p>
            But with these new betting games, there are a few things to keep in
            mind. Firstly, virtual sports betting relies on RNG (Random Number
            Generator) software to determine the outcome of events. While the
            software is thoroughly tested by independent third-party auditors
            and certified as fair and random, it's important to remember that
            there's no inherent strategy to virtual sports betting. So, you're
            always taking a chance.{" "}
          </p>
          <p>
            Secondly, our live casino games adhere to the same rules and
            regulations as a physical casino. This means that you'll be playing
            with real money and will have the possibility to win big. However,
            it's crucial to stay within your budget and not chase losses, as
            gambling is a risk and should be approached with caution.
          </p>
          <p>
            Lastly, remember to have fun! Betting games are meant to entertain
            and provide some excitement in your day. Always play responsibly and
            within your limits.
          </p>
          <p>
            So, what are you waiting for? Head over to my website and try out
            our latest betting games. Who knows, you might just hit it big!
          </p>
        </div>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default BeforeResponsible;
