import React, { useEffect, useState } from "react";
import MenuFootIcon from "../assets/images/MenuFootIcon.png";
import SportsFootIcon from "../assets/images/SportsFootIcon.png";
import CasinoFootIcon from "../assets/images/CasinoFootIcon.png";
import WalletFootIcon from "../assets/images/WalletFootIcon.png";
import ChatFootIcon from "../assets/images/ChatFootIcon.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import ProfileIcon from "../assets/svg/ProfileIcon";
import logo from "../assets/svg/logo_new.png";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const Footer = () => {
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "deposit",
    "cashier",
    "casino/supernowa",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-links mb-5">
          <ul>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicy">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegame">Responsible Gaming</a>
            </li>
            <li>
              <a href="#">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrule">Games Rules</a>
            </li>
            <li>
              <a href="/termsconditions">Terms and Conditions</a>
            </li>
          </ul>
        </div>
        <div className="footer-logo">
          {appDetails?.LOGO_URL && <img src={appDetails?.LOGO_URL || logo} />}
        </div>
        <p>{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="paymentImages mb-4">
          <ul>
            <li>
              <img src="./images/master-card.png" />
            </li>
            <li>
              <img src="./images/visa-card.png" />
            </li>
          </ul>
        </div>

        <div className="secureGaming">
          <ul>
            <li>
              <img src="./images/GA-partner.png" />
            </li>
            <li>
              <img src="./images/gamecare-partner.png" />
            </li>
            <li>
              <img src="./images/mga-partner.png" />
            </li>
            <li>
              <img src="./images/age18.png" />
            </li>
          </ul>
        </div>

        <div className="social-icons">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>

            <li>
              <a
                className="panel"
                href={
                  appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a className={activePage === "home" ? "active" : ""} href="/home">
                {/* <MenuFootIcon /> */}
                <img src={MenuFootIcon} alt="Menu Icon" />
                <span>Menu</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "betby" ? "active" : ""}
                href="/betby"
              >
                {/* <SportsFootIcon /> */}
                <img src={SportsFootIcon} alt="Sports Icon" />
                <span>Sports</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "casino/supernowa" ? "active" : ""}
                href="/casino/supernowa"
              >
                {/* <CasinoFootIcon /> */}
                <img src={CasinoFootIcon} alt="Casino Icon" />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "cashier" ? "active" : ""}
                href="/cashier"
              >
                {/* <WalletFootIcon /> */}
                <img src={WalletFootIcon} alt="Wallet Icon" />
                <span>Wallet</span>
              </a>
            </li>
            <li>
              <a
                href={`https://wa.me/${appDetails?.WHATS_APP_URL}`}
                target="_blank"
              >
                {/* <ChatFootIcon /> */}
                <img src={ChatFootIcon} alt="Chat Icon" />
                <span>Chat</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
